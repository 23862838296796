import { Controller } from 'stimulus'
import {
  buildElement,
  buildTextNode
} from "helpers";
// import { buildElement } from '../../helpers';

export default class extends Controller {
  static targets = ['form', 'count', 'input', 'items']

  selectFileClick() {
    this.inputTarget.click()
  }
  
  fileSelect() {
    this.files = Array.from(this.inputTarget.files)
    
    this.total_size = 0
    this.items_progress = []
    this.files.forEach((file, index) => {
      this.total_size = this.total_size + file.size
      this.items_progress[index] = [0, file.size, 'new']
    })

    // TODO
    // - clear inputTarget files
    
    this.openUpload()
    this.files.forEach((file, index) => this.send(file, index))

  }

  openUpload() {
    let template = document.getElementById('upload-modal-content')
    let modalContent = template.content.cloneNode(true)

    let progress = buildElement('div', { class: 'progress' })
    let progress_bar = buildElement('div', { id: 'upload-progress-bar', class: 'progress-bar bg-cyan', role: 'progressbar', style: 'width: 0%', 'aria-valuenow': 0, 'aria-valuemin': 0, 'aria-valuemax': 100 })
    let progress_text = buildTextNode('0%')
    progress_bar.appendChild(progress_text)
    progress.appendChild(progress_bar)
    modalContent.getElementById('modalBody').appendChild(progress)

    let status = buildElement('small', { id: 'upload-status-text', class: 'text-muted' })
    let status_text = buildTextNode('0 von ' + this.files.length + ' Dateien übertragen')
    status.appendChild(status_text)
    modalContent.getElementById('modalBody').appendChild(status)

    let oldModalContent = document.getElementById('modal_content')
    oldModalContent.parentNode.replaceChild(modalContent, oldModalContent);
    
    const modalElem = document.getElementById('modal')
    modalElem.modal.open()
  }

  send(file, index) {
    let cntr = this
    let xhr = new XMLHttpRequest()
    let formData = new FormData()
    xhr.open('POST', this.formTarget.dataset.url, true)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    xhr.setRequestHeader('X-CSRF-Token', this.getMetaValue("csrf-token"))

    // upload progress
    xhr.upload.addEventListener('progress', (event) => ((index, cntr) => {
      cntr.progress(event, index)
    })(index, this))

    // upload complete
    xhr.upload.addEventListener("load", (event) => ((index, cntr) => {
      cntr.finished(event, index)
    })(index, this))

    // TODO
    // - handle errors
    // xhr.addEventListener('error', this.handleError.bind(null, index, xhr), false)

    formData.append('file', file)
    formData.append('sort', parseInt(this.countTarget.value) + index + 1)
    xhr.send(formData)
  }

  progress(event, index) {
    this.items_progress[index][0] = event.loaded
    this.items_progress[index][2] = event.type
    let percent = Math.round((this.getLoadedSize() / this.total_size) * 100)
    let bar = document.getElementById('upload-progress-bar')
    bar.setAttribute('style', 'width: ' + percent + '%')
    bar.setAttribute('aria-valuenow', percent)
    bar.textContent = percent + '%'

    // if (percent == 100) {
    //   setTimeout(this.closeModal(), 2000)
    // }
  }

  finished(event, index) {
    this.items_progress[index][0] = event.loaded
    this.items_progress[index][2] = event.type

    let loadedFiles = this.getLoadedFiles()
    let text = document.getElementById('upload-status-text')
    text.textContent = loadedFiles + ' von ' + this.files.length + ' Dateien übertragen'

    // if (loadedFiles == this.files.length) {
    //   setTimeout(this.closeModal(), 2000)
    // }
  }

  closeModal() {
    // $('#modal').modal('hide')
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }

  getLoadedSize() {
    var loaded = 0
    this.items_progress.forEach((file) => {
      loaded = loaded + file[0]
    })
    return loaded
  }

  getLoadedFiles() {
    var loaded = 0
    this.items_progress.forEach((file) => {
      if (file[2] == 'load') {
        loaded = loaded + 1
      }
    })
    return loaded
  }
}