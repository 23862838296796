import Rails from "@rails/ujs"

Rails.confirm = function(message, element) {

  let template = document.getElementById('confirm-modal-content')
  let modalContent = template.content.cloneNode(true)

  modalContent.getElementById('modalTitle').textContent = element.dataset.title
  modalContent.getElementById('modalBody').textContent = message

  let oldModalContent = document.getElementById('modal_content')
  oldModalContent.parentNode.replaceChild(modalContent, oldModalContent);

  const modalElem = document.getElementById('modal')
  modalElem.modal.open()

  // let permission = $(".modal-body").find(".permission-form")
  // if (permission.length > 0) {
  //   $(".modal-body").find(".permissions-index").hide()
  //   $(".modal-body").append(message)
  // } else {
  //   $(".modal-body").html(message)
  // }

  modalElem.querySelectorAll('.btn-confirm').forEach(function(confirm) {
    confirm.addEventListener('click', function(event) {
      event.preventDefault()
      modalElem.modal.cancel()

      let old = Rails.confirm

      Rails.confirm = function() { return true }
      element.click()
      Rails.confirm = old
    })
  })

  return false
}
Rails.start()