/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'rails/custom_ujs'
require("@rails/activestorage").start()
require("channels")
require("@hotwired/turbo-rails")

import '@popperjs/core'
import { Tooltip } from 'bootstrap';

// import '../stylesheets/material-icons/material-icons.css'

require("trix")
require("@rails/actiontext")

import 'controllers'

function openAppbar(event) {
  event.preventDefault()
  document.body.classList.add('appbar-open')
}

function closeAppbar() {
  document.body.classList.remove('appbar-open')
}

function reloadDropdownMenuOnClick() {
  let dropdownOnClicks = document.querySelectorAll('.dropdown-onclick');

  dropdownOnClicks.forEach(el => el.addEventListener('click', event => {
    var dropdown = document.querySelector('div[aria-labelledby="' + event.currentTarget.id + '"')
    var frame = dropdown.querySelector('turbo-frame')
    var src = event.currentTarget.getAttribute('data-src')

    if (src != '') {
      frame.src = src
      event.currentTarget.setAttribute('data-src', '')
    }
  }));
}

document.addEventListener("turbo:load", function() {
  var navbarApps = document.getElementById("navbar-apps")
  if (navbarApps) {
    navbarApps.addEventListener("click", openAppbar)
  }

  var overlay = document.getElementById("overlay")
  if (overlay) {
    overlay.addEventListener("click", closeAppbar)
  }

  document.querySelectorAll("#appbar a").forEach(function(elem) {
    elem.addEventListener("click", closeAppbar)
  })

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })

  reloadDropdownMenuOnClick()
})

document.addEventListener("turbo:before-fetch-response", function (e) {
  reloadDropdownMenuOnClick()
})