import { Controller } from 'stimulus'
import {
  getCookie, 
  setCookie
} from "helpers";

export default class extends Controller {
  static targets = []

  initialize() {
    this.showCookieNote()
  }

  showCookieNote() {
    let allowed_coockies = getCookie("klind_allowed");
    if (!allowed_coockies) {
      document.body.classList.add('show-cookie-note')
    }
  }

  allowAllCookies() {
    setCookie("klind_allowed", JSON.stringify(['all']), 365)
    document.body.classList.remove('show-cookie-note')
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
  }
}