import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['infoLink', 'chatLink', 'shareLink', 'historyLink']

  initialize() {
    this.toggleInfobar(true)
    this.activeNavItem()
  }

  toggleDetails(event) {
    event.preventDefault();
   
    this.setAnimation()    
    this.toggleSessionStorage('details')
    this.toggleInfobar()
    this.activeNavItem()
  }

  toggleComments(event) {
    event.preventDefault();
   
    this.setAnimation()    
    this.toggleSessionStorage('comments')
    this.toggleInfobar()
    this.activeNavItem()
  }

  togglePermissions(event) {
    event.preventDefault();
   
    this.setAnimation()    
    this.toggleSessionStorage('permissions')
    this.toggleInfobar()
    this.activeNavItem()
  }

  toggleSessionStorage(part) {
    let infobar = sessionStorage.getItem('infobar')
    if (infobar != "undefined" && infobar != null && infobar == part) {
      sessionStorage.removeItem('infobar')
    } else {
      sessionStorage.setItem('infobar', part)
    }
  }

  toggleInfobar() {
    let infobar = sessionStorage.getItem('infobar')
    let infobarElem = document.getElementById('infobar')

    if (infobar != "undefined" && infobar != null) {
      infobarElem.classList.add('open')

      // show infobar section
      let sections = infobarElem.getElementsByClassName('section')
      for (let i = 0; i < sections.length; i++) {
        sections[i].classList.add('d-none')
      }
      infobarElem.getElementsByClassName(infobar)[0].classList.remove('d-none')

      // fade out sidebar if active
      let navbarCtr = this.getControllerByIdentifier('navbar')
      if (navbarCtr) {
        navbarCtr.clear()
      }
    } else {
      infobarElem.classList.remove('open')
    }
  }

  activeNavItem() {
    let infobar = sessionStorage.getItem('infobar')
    let infobarElem = document.getElementById('infobar')
    let links = infobarElem.getElementsByClassName('nav-link')
    
    for (let link of links) {
      if (link.classList.contains(infobar)) {
        link.classList.add('active')
      } else {
        link.classList.remove('active')
      }
    }
  }

  clear() {
    sessionStorage.removeItem('infobar')
    this.setAnimation()    
    this.toggleInfobar()
    this.activeNavItem()
  }

  setAnimation() {
    let infobarElem = document.getElementById('infobar')
    infobarElem.classList.add('animate')
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }
}