import { Controller } from 'stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['userLink', 'notificationsLink']

  initialize() {
    let userNav = document.getElementById('user-nav')
    if (userNav.classList.contains('signed-in')) {
      this.toggleSidebar()
    }
  }

  toggleUser(event) {
    event.preventDefault();
   
    this.toggleSessionStorage('user')
    this.setAnimation()
    this.toggleSidebar()
  }

  toggleNotifications(event) {
    event.preventDefault()

    
    this.toggleSessionStorage('notifications')
    this.setAnimation()
    this.toggleSidebar()
  }

  toggleSessionStorage(part) {
    let sidebar = sessionStorage.getItem('sidebar')
    if (sidebar != "undefined" && sidebar != null && sidebar == part) {
      sessionStorage.removeItem('sidebar')
    } else {
      sessionStorage.setItem('sidebar', part)
    }
  }

  toggleSidebar() {
    let sidebar = sessionStorage.getItem('sidebar')
    let wrapperElem = document.getElementById('wrapper')

    if (sidebar != "undefined" && sidebar != null) {
      wrapperElem.classList.add('sidebar-open')
      
      // show sidebar section
      let sidebarWrapperElem = document.getElementById('sidebar-wrapper')
      let sections = sidebarWrapperElem.getElementsByClassName('section')
      for (let i = 0; i < sections.length; i++) {
        sections[i].classList.add('d-none')
      }
      sidebarWrapperElem.getElementsByClassName(sidebar)[0].classList.remove('d-none')

      // mark notifications as readed if sidebar == notifications
      this.markNotificationsAsRead(sidebar)
      

      // fade out infobar if active
      let infobarCtr = this.getControllerByIdentifier('cloud--infobar')
      if (infobarCtr) {
        infobarCtr.clear()
      }
    } else {
      wrapperElem.classList.remove('sidebar-open')
    }
  }

  openFileSelect(event) {
    event.preventDefault();
    
    let folderCtr = this.getControllerByIdentifier('cloud--folder')
    if (folderCtr) {
      folderCtr.selectFileClick()
    }
  }

  clear() {
    sessionStorage.removeItem('sidebar')
    this.setAnimation()    
    this.toggleSidebar()
  }

  setAnimation() {
    let wrapperElem = document.getElementById('wrapper')
    wrapperElem.classList.add('animate')
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  markNotificationsAsRead(sidebar) {
    if (sidebar == 'notifications') {
      const request = new FetchRequest('post', this.notificationsLinkTarget.dataset['readUrl'], { responseKind: 'json' })
      request.perform()
    }
  }
}