import { Controller } from 'stimulus'
import Sortable from "sortablejs"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let newPosition = event.newIndex + 1

    const request = new FetchRequest('post', this.element.dataset.dragUrl.replace(":id", id), { responseKind: 'json', body: this.updatePositionParams(newPosition) })
    request.perform()
  }

  updatePositionParams(newPosition) {
    return JSON.stringify({ 
      project: { 
        position: newPosition
      }
    })
  }
}