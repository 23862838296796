import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.modalController.open()
  }

  get modalController() {
    const elem = document.getElementsByClassName('modal')
    return this.application.getControllerForElementAndIdentifier(elem[0], "modal")
  }
}