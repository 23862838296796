import { Controller } from "stimulus"
import { Modal } from 'bootstrap';
export default class extends Controller {
  static targets = ['dialog', 'content']

  connect() {
    this.element[this.identifier] = this;
    this.modal = new Modal(this.element, {
      keyboard: false
    })
  }

  open() {
    this.dialogTarget.classList.remove('modal-sm')
    if (this.contentTarget.dataset['size'] !== undefined) {
      this.dialogTarget.classList.add(this.contentTarget.dataset['size'])
    }
    this.modal.show()
  }

  close({ detail: { success } }) {
    if (success) {
      this.modal.hide()
    }
  }

  cancel() {
    this.modal.hide()
  }
}