import { Controller } from 'stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'form', 'completed']

  updateCompleted() {
    const request = new FetchRequest('put', this.formTarget.action, { responseKind: 'json', body: this.updateCompletedParams() })
    request.perform()
  }

  updateCompletedParams() {
    return JSON.stringify({ 
      task: { 
        completed: this.completedTarget.checked 
      }
    })
  }
}